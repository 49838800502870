import React, {useState, Component} from "react";
import GradientBanner from "./GradientBanner";
import TitleText from "./TitleText";
import Spacer from "./Spacer";

import './App.css';


function CenterTitleBanner(props) {
    return (
      <>
        <TitleText y={190} center={true} color={'white'}>
            {props.text}
            <div className='title-underline' style={{margin: 'auto', color: 'white'}}></div>
        </TitleText>
        <GradientBanner src={require(`${props.src}`)}/>
        <Spacer height={'250px'}/>
      </>
    );
}

export default CenterTitleBanner;