import React, {Component} from 'react';
import {
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import CenterTitleBanner from './CenterTitleBanner';

import Txt from './Txt';


function CaseResults() {
    let txt = Txt.getTxt();

    return (
        <>
        <CenterTitleBanner text={txt.navigation_bar[13]} src="./images/pillars_blue.jpeg"/>
        <div className='body-container'>
            <div className='flex-container'>
                <div className='flex-section'>
                    <div id='heading-box' className='headertext2 caseresults-summary'>
                        {txt.case_results_statistic}
                    </div>
                </div>
                <div className='flex-section'>
                    <div id='heading-description' className='bodytext3'>
                        {txt.case_results_summary}
                    </div>
                </div>
            </div>
        </div>

        <div className='caseresults-container'>
            {
                txt.case_results.map((result) => (<CaseResult amount={result.won} text={result.category} description={result.description}/>))
            }
        </div>
        </>
    );
}

function updateHeading(amount, description, event) {
    console.log("woah there");
    document.getElementById('heading-box').textContent = amount + " WON";
    document.getElementById('heading-description').textContent = description;
}

function CaseResult(props) {
    return (
        <div className='caseresult' onClick={(e) => updateHeading(props.amount, props.description, e)}>
            <div className='caseresult-amount'>{props.amount}</div>
            <div className='caseresult-divider'></div>
            <div className='caseresult-text'>{props.text}</div>
        </div>
    );
}

export default CaseResults;