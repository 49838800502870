import React, {useState, Component} from "react";

import './App.css';


function Blog() {
    return (
        <h2>Blog goes here</h2>
    );
}

export default Blog;