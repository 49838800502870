import React, {Component} from 'react';
import {
  Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from "react-router-dom";
import Navbar from './Navbar';
import Image from './Image';
import TitleText from './TitleText';
import SubtitleText from './SubtitleText';
import TitleBanner from './TitleBanner';
import Spacer from './Spacer';
import Footer from './Footer';
import ContactForm from './ContactForm';
import AttorneyCarousel from './AttorneyCarousel';
import AttorneyProfile from './AttorneyProfile';
import PracticeArea from './PracticeArea';
import Blog from './Blog';
import CaseResults from './CaseResults';

import img_pillars from './images/pillars_blue.jpeg'; 
import img_building from './images/building_blue.jpeg'; 
import img_skyline from './images/skyline_blue.jpeg';

import Txt from './Txt';


function App() {
  function handleScroll(e) {
    console.log(window.scrollY);
    if (window.scrollY > 20) {
      [...document.getElementsByClassName("navbutton")].forEach((nb) => (nb.style.height = "66px"))
    } else {
      [...document.getElementsByClassName("navbutton")].forEach((nb) => (nb.style.height = "80px"))
    }
  }

  window.addEventListener("scroll", (e) => handleScroll(e));

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Cabin&family=Cinzel:wght@500&family=Lato:wght@300&family=Prata&display=swap" rel="stylesheet"/>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="about" element={<AboutPage />}/>
        <Route path="attorneys/" element={<Attorneys />}/>
        <Route path="attorneys/:name" element={<AttorneyProfile />}/>
        <Route path="faq/" element={<FrequentlyAskedQuestions />}/>
        <Route path="practice-areas/" element={<PracticeArea />}/>
        <Route path="personal-injury/" element={<PracticeArea />}/>
        <Route path="personal-injury/:category" element={<PracticeArea />}/>
        <Route path="workers-comp/" element={<PracticeArea />}/>
        <Route path="workers-comp/:category" element={<PracticeArea />}/>
        <Route path="who-we-represent/" element={<PracticeArea />}/>
        <Route path="who-we-represent/:category" element={<PracticeArea />}/>
        <Route path="results" element={<CaseResults />}/>
        <Route path="reviews" element={<ReviewsPage />}/>
        <Route path="contact-us" element={<ContactUsPage />}/>
      </Routes>

      <ContactForm/>

      <Footer/>
    </>
  );
}

function Home() {
  let txt = Txt.getTxt();

  return (
    <>
    <TitleText y={190} center={true} color={'white'}>
      McHargue & Jones
      <div className='title-underline' style={{margin: 'auto', color: 'white'}}></div>
    </TitleText>
    <SubtitleText y={330} center={true} color={'white'}>
      {txt.homepage.slogan}
    </SubtitleText>
    {/*<div id="homebanner"></div>*/}

    <div id='ataglance' className='flex-container'>
      <div className='ataglance-section'>
        <div className='big'>{txt.homepage.statistics[0]}</div> <br/> {txt.homepage.statistics[1]}
      </div>
      <div className='ataglance-section'>
        <div className='big'>{txt.homepage.statistics[2]}</div> <br/> {txt.homepage.statistics[3]}
      </div>
      <div className='ataglance-section'>
        <div className='big'>{txt.homepage.statistics[4]}</div> <br/> {txt.homepage.statistics[5]}
      </div>
    </div>

    <Image x={0} y={100} height={585} src={require(`./chicago.jpeg`)}/>
    
    <img id='pic_jim' src={require('./images/stock_lawyer.png')}></img>
    <img id='pic_matt' src={require('./images/stock_lawyer.png')}></img>
    
    {/*<div className='panel' style={{height: '500px'}}></div>*/}
    <Spacer height={'315px'}/>
    <div className='shortcut-container'>
      <div className='shortcut'>{txt.homepage.buttons[0]}</div>
      <Link to="/contact-us"><div className='shortcut' style={{'flex-grow': 3}}>{txt.homepage.buttons[1]}</div></Link>
      <div className='shortcut'>{txt.homepage.buttons[2]}</div>
    </div>
    <Spacer height={'175px'}/>

    {/*<TitleText x={70} y={650} color={'white'} fontsize={50}>Chicago's Leading Personal Injury <br/> and Worker's Compensation Lawyers</TitleText>*/}

    <div className='body-container'>
      <div className='flex-container'>
        <div className='flex-section'>
          <div className='headertext2'>
            {txt.homepage.heading}
          </div>
        </div>
        <div className='flex-section'>
          <div className='bodytext2'>
            {txt.homepage.elevator_pitch}
          </div>
        </div>
      </div>
      
      
      <div className='stat-container'>
        <div className='stat-section'>
          {txt.homepage.links[0]}
          <img src={require('./images/icon_moneybag.jpeg')}></img>
          <div><Link to={'results'}>{txt.navigation_bar[13]}</Link></div>
        </div>
        <div className='stat-section'>
          {txt.homepage.links[1]}
          <img src={require('./images/icon_searstower.jpeg')}></img>
          <div><Link to={'attorneys'}>{txt.navigation_bar[2]}</Link></div>
        </div>
        <div className='stat-section'>
          {txt.homepage.links[2]}
          <img src={require('./images/icon_handshake.jpeg')}></img>
          <div><Link to={'reviews'}>{txt.navigation_bar[14]}</Link></div>
        </div>
        <div className='stat-section'>
          {txt.homepage.links[3]}
          <img src={require('./images/icon_gavel.jpeg')}></img>
          <div><Link to={'faq'}>{txt.navigation_bar[3]}</Link></div>
        </div>
      </div>
    </div>

    <div className='body-container' style={{backgroundImage: `url(${img_pillars})`, border: '8px solid black', padding: '32px 100px'}}>
      <div className="headertext" style={{color: 'white', margin: '20px 20px'}}>
        {txt.homepage.heading2}
      </div>
      <div className='carousel-container'>
        <div className='carousel'>
          {txt.homepage.case_results.map((c) => (
          <CarouselElement winnings={c.amount} category={c.category}>{c.description}</CarouselElement>
          ))}
        </div>
      </div>
    </div>

    {/*
    <CarouselElement winnings={cr.amount} category={cr.category}>hi</CarouselElement>
            </>
    <div className='body-container'>
      <div className='titletext' style={{padding: '0px 50px'}}>
          What We Do
      </div>
      <img src={require('./helping_people.jpeg')} style={{position: 'relative', top: '-176px', left: '680px', margin: '0 0 -360px 0'}}></img>
      <div className='bodytext' style={{'max-width': '550px', margin: '0px 80px'}}>
        If you or someone you love sustained an injury at work or as a result of someone else’s negligence, turn to the trusted Chicago injury attorneys at McHargue & Jones, LLC today. Since 2000, we have helped individuals throughout Illinois recover compensation for their losses; winning millions of dollars on their behalves.
      </div>
      <div className='bodytext twocolumn'>
      Our aggressive attorneys can fight for the maximum compensation to which you are entitled as we navigate you through each stage of the process. We can help you make well-informed decisions along the way while ensuring your rights are completely protected. Whether you were injured in a motor vehicle accident, are suffering from a work-related illness or injury, were injured by a defective product, or anything similar, we can handle it. Our firm truly cares about our clients, and we relentlessly fight for the compensation they deserve. You can depend on us to provide you with the compassionate and committed advocacy you need.

      As you face the insurance company, you don't have to do so alone. We have vast experience taking on insurance companies in all types of cases, from car accidents to workers' compensation. In fact, we are frequently hired by injured parties who have previously hired another firm that failed to aggressively and properly represent them. We have recovered millions of dollars for those we've represented over the years and look forward to seeking the highest settlement available for you and your family.
      </div>
    </div>
    */}
    <div className='relative-container'>
      <img id='floatimage' className='float' src={require('./helping_people.jpeg')}></img>
      <div id='floattext' className='float'>
        <div className='headertext2'>
          {txt.homepage.mission_heading}
        </div>
        <div className='bodytext2'>
          {txt.homepage.mission}
        </div>
      </div>
    </div>

    <Spacer height={'380px'}/>
    <div className='body-container'>
      <div className='flex-container'>
        <div className='flex-section'>
          <div className='bodytext3'>
            {txt.homepage.long_description1.map((t) =>
              <>{t}<br/></>
            )}
          </div>
        </div>
        <div className='flex-section'>
          <div className='bodytext3'>
            {txt.homepage.long_description2.map((t) =>
              <>{t}<br/></>
            )}
          </div>
        </div>
      </div>
    </div>


    <div className='body-container' style={{backgroundImage: `url(${img_building})`}}>
      <div className="headertext" style={{color: 'white', textAlign: 'center'}}>
        {txt.navigation_bar[5]}
      </div>
    </div>
    <div className='body-container' style={{backgroundColor: '#15294b', boxShadow: 'inset black 0px 0px 10px -2px'}}>
      <div className='flex-container practice-container' style={{color: 'white'}}>
        <Link to={'personal-injury'} className='practice-section' style={{backgroundImage: `url("${require('./images/banner_pi.jpeg')}")`}}>
          {txt.navigation_bar[7]}
        </Link>
        <Link to={'workers-comp'} className='practice-section' style={{backgroundImage: `url("${require('./images/factory_bg_dark.jpeg')}")`}}>
          {txt.navigation_bar[6]}
        </Link>
        <Link to={'personal-injury/car-accidents'} className='practice-section' style={{backgroundImage: `url("${require('./images/banner_car_crash.jpeg')}")`}}>
          {txt.navigation_bar[9]}
        </Link>
        <Link to={'personal-injury/truck-accidents'} className='practice-section' style={{backgroundImage: `url("${require('./images/banner_truck_crash.jpeg')}")`}}>
          {txt.navigation_bar[11]}
        </Link>
        <Link to={'personal-injury/premises'} className='practice-section' style={{backgroundImage: `url("${require('./images/banner_premises.jpeg')}")`}}>
          {txt.navigation_bar[10]}
        </Link>
        <Link to={'personal-injury/animals'} className='practice-section' style={{backgroundImage: `url("${require('./images/banner_animal_attacks.jpeg')}")`}}>
          {txt.navigation_bar[8]}
        </Link>
      </div>
    </div>

    <div className='body-container'>
      <div className='relative-container'>
        {txt.homepage.testimonials.map((t, i) => (
          <Testimonial x={(120 * i) + 50} y={(93 * i)}>"{t.text}"<br/><i>{t.author}</i></Testimonial>
        ))}
      </div>
      <Spacer height={'520px'}/>
      <div className="headertext">
        {txt.navigation_bar[14]}
      </div>
      <div id="testimonial-button"><Link to={'reviews'}>{txt.homepage.see_more}</Link></div>
      <Spacer height={'75px'}/>
    </div>

    <Spacer height={'100px'}/>
    </>
  );
}

function CarouselElement(props) {
  return (
    <a href='results'>
    <div className='carousel-section'>
      <h1>{props.winnings}</h1>
      <div>
        <b>{props.category}</b><br/>
        {props.children}
      </div>
    </div>
    </a>
  );
}

function Testimonial(props) {
  return (
    <div className='testimonial float' style={{'top': `${props.y}px`, 'left': `${props.x}px`}}>{props.children}</div>
  )
}

function AboutPage() {
  let txt = Txt.getTxt();

  return (
    <>
    <TitleBanner text="About Us" src="./images/banner_handshake.jpeg"/>
    <div className='body-container'>
      <div className='headertext' style={{margin: '50px 120px', paddingBottom: '16px', borderBottom: '2px solid #f0f0f0', borderRadius: '2px'}}>{txt.about_us.heading}</div>
      <div className='flex-container'>
        <div className='flex-section'>
          <div className='bodytext3'>
          {txt.about_us.short_description1}
          </div>
        </div>
        <div className='flex-section'>
          <div className='bodytext3'>
          {txt.about_us.short_description2}
          </div>
        </div>
      </div>
    
      <div className='stat-container'>
        <div className='stat-section aboutpage'>
          {txt.homepage.links[1]}
          <img src={require('./images/icon_gavel.jpeg')}></img>
          <div><Link to={'attorneys'}>{txt.navigation_bar[2]}</Link></div>
        </div>
        <div className='stat-section aboutpage'>
          {txt.homepage.links[0]}
          <img src={require('./images/icon_moneybag.jpeg')}></img>
          <div><Link to={'results'}>{txt.navigation_bar[13]}</Link></div>
        </div>
        <div className='stat-section aboutpage'>
          {txt.homepage.links[3]}
          <img src={require('./images/icon_gavel.jpeg')}></img>
          <div><Link to={'faq'}>{txt.navigation_bar[3]}</Link></div>
        </div>
      </div>
    </div>

    <div className='relative-container'>
    <img id='floatimage' className='float' src={require('./helping_people.jpeg')}></img>
      <div id='floattext' className='float'>
        <div className='headertext2'>
          {txt.homepage.mission_heading}
        </div>
        <div className='bodytext2'>
          {txt.homepage.mission}
        </div>
      </div>
    </div>

    <div style={{height: "450px", backgroundImage: `url(${img_building})`, boxShadow: 'black 0px 0px 10px -2px inset'}}/>
    <div className='body-container'>
      <div className='flex-container'>
        <div className='flex-section'>
          <div className='bodytext3'>
            {txt.about_us.long_description1.map((t) => {
              <>{t}<br/></>
            })}
          </div>
        </div>
        <div className='flex-section'>
          <div className='bodytext3'>
            {txt.about_us.long_description2.map((t) => {
              <>{t}<br/></>
            })}
          </div>
        </div>
      </div>
    </div>
    <AttorneyCarousel/>
    <Spacer height={'50px'}/>
    </>
  );
}

function Attorneys(props) {
  let txt = Txt.getTxt();
  
  return (
    <>
    <TitleBanner text="Meet the Team" src="./images/banner_handshake.jpeg"/>
    <div className="bodytext">
      {txt.attorney_overview}
    </div>
    <AttorneyCards/>
    </>
  );
}

function AttorneyCards() {
  let attorneys = [
    {name: "James P. McHargue", link: "james-mchargue", image: "James_McHargue"},
    {name: "Matthew C. Jones", link: "matthew-jones", image: "Matt_Jones"},
    {name: "Brenton M. Schmitz", link: "brenton-schmitz", image: "Brent_Schmitz"},
    {name: "Jeff Naffziger", link: "jeff-naffziger", image: "Jeff_Naffziger"},
    {name: "Daniel R. Klosowski", link: "daniel-klosowski", image: "Dan_Klosowski"},
    {name: "Adam Rosner", link: "adam-rosner", image: "Adam_Rosner"}
  ];

  return (
    <div className="attorney-container">
      {attorneys.map((attorney) => (
        <div className="attorney-card">
          <Link to={attorney.link} style={{"textDecoration": "none"}}>
            <div className="card-contents" style={{backgroundImage: `url(${require('./images/attorney_' + attorney.image + '.jpeg')})`}}>
            <div className='attorney-name'>{attorney.name}</div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

function BWTitleBanner(props) {
  let textColor = props.textColor == null ? 'black' : props.textColor;
  let textSize = props.textSize == null ? '70px' : props.textSize;
  return (
    <div style={{marginTop: '120px', marginLeft: '125px'}}>
      <div className='bwtitletext' style={{color: textColor, fontSize: textSize}}>{props.text}</div>
      <div className='title-underline' style={{backgroundColor: textColor, opacity: 0.07}}></div>
    </div>
  )
}

function FrequentlyAskedQuestions() {
  let txt = Txt.getTxt();
  return (
    <>
    <BWTitleBanner text={txt.faq.heading}/>
    <div className='faq-text'>
      <div className='faq-navbar'>
        {txt.faq.questions.map((q, i) => (
          <a href={'#question' + i}>{q.q}</a>
        ))}
      </div>

      {txt.faq.questions.map((q, i) => (
        <><h1>{q.q}<nav id={'question' + i}></nav></h1>
        <p>{q.a}</p></>
      ))}
    </div>

    </>
  );
}

class ReviewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {pageIndex: 0};
  }

  decreasePageNumber = () => {
    let newpi = this.state.pageIndex > 0 ? this.state.pageIndex - 1 : 1;
    this.setState({pageIndex: newpi});
  }

  increasePageNumber = () => {
    let newpi = this.state.pageIndex < 1 ? this.state.pageIndex + 1 : 0;
    this.setState({pageIndex: newpi});
  }

  render() {
    let txt = Txt.getTxt();
    let pages = txt.testimonials.pages;
    let currentPage = pages[this.state.pageIndex];
    return (
      <>
        <div className='testimonial-bg'>
          <div style={{backgroundColor: '#15294be0', paddingTop: '1px'}}>
          <BWTitleBanner text={txt.navigation_bar[14]} textColor='white' textSize='90px'/>
          <div className='testimonial-container'>
            <div className='testimonial-card'>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Zwh1gYpCLe8?si=Ym_ni_Dr1Jx5rS5X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h1>{txt.testimonials.videos[0].title}</h1>
              <p>
                {txt.testimonials.videos[0].description}
              </p>
            </div>
            <div className='testimonial-card'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-Djq8XJOAeM?si=lC2yEf1v0r47pIIY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h1>{txt.testimonials.videos[1].title}</h1>
              <p>
                {txt.testimonials.videos[1].description}
              </p>
            </div>
            <div className='testimonial-card'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/t1dWlBx9vOM?si=g1FNPHBHe2n3Q8it" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <h1>{txt.testimonials.videos[2].title}</h1>
              <p>
                {txt.testimonials.videos[2].description}
              </p>
            </div>
          </div>
          </div>
        </div>

        <BWTitleBanner text={txt.testimonials.reviews_heading}/>
        <div className='pagebar'>
          <div id='page-left' onClick={this.decreasePageNumber}>
            ❮
          </div>
          <div id='page-number'>
            {this.state.pageIndex + 1}
          </div>
          <div id='page-right' onClick={this.increasePageNumber}>
            ❯
          </div>
        </div>
        <div className='review-container'>
          {currentPage.map((t, i) => (
            <div className='testimonial' style={{position: 'static', margin: 'auto', marginBottom: '15px'}}>
              <Testimonial>"{t.text}"<br/><i>{t.author}</i></Testimonial>
              "{t.text}"<br/>
              <i>{t.author}</i>
            </div>
          ))}
        </div>

        
      </>
    );
  }
}


function ContactUsPage() {
  let txt = Txt.getTxt();
  return (
    <>
      <TitleText y={190} center={true} color={'black'}>
        {txt.navigation_bar[15]}
        <div className='title-underline' style={{margin: 'auto', backgroundColor: '#00000033'}}></div>
      </TitleText>
      <Spacer height={'200px'}/>

      <div className='contact-container'>
        <a className='contact-card' href='mailto:mcharguelaw@hotmail.com'>
          <img src={require('./images/link_email.png')}></img>
        </a>
        <a className='contact-card' href='https://www.facebook.com/mcharguelaw/'>
          <img src={require('./images/link_facebook.png')}></img>
        </a>
        <a className='contact-card' onClick={(e) => {window.open('tel:3127390000');}}>
          <img src={require('./images/link_phone.png')}></img>
        </a>
        <a className='contact-card' href='https://www.linkedin.com/company/mchargue-jones-llc'>
          <img src={require('./images/link_linkedin.png')}></img>
        </a>
        <a className='contact-card' href='https://www.youtube.com/channel/UCsv08hHg1OczkbeFe2X5O-w/featured'>
          <img src={require('./images/link_youtube.png')}></img>
        </a>
      </div>

      <div className='contact-container'>
        <span className='contact-header'>312-739-0000</span>
      </div>

      <div style={{padding: '10px 0 30px'}}>
        <div className='bodytext2 contact-info'>
          <b>{txt.footer.address}</b>
          {txt.footer.hours}<br style={{marginTop: '8px'}}/>
          <a href={"https://goo.gl/maps/icwt1GgruW1ezAAcA"} target="_blank" style={{textDecoration: 'underline'}}>{txt.footer.directions}</a>
        </div>
      </div>
    </>
  );
}

export default App;

