import React, {useState, Component} from "react";

import './App.css';
import emailjs from '@emailjs/browser';

import Txt from './Txt';


function ContactForm() {
    let txt = Txt.getTxt();

    const submitForm = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_t2paj8i', 'template_90azw4y', e.target, 'hw3BE-qSxgq-mbqKP');
    }

    return (
      <div className='contact-form-bg' style={{backgroundImage: `url("${require('./images/skyline_blue_crop.jpeg')}")`}}>
        <div className='contact-form'>
          <div className='flex-container' style={{padding: 0}}>
            <div className='flex-section'>
              <div className='headertext' style={{color: 'white', margin: '150px 70px 20px'}}>{txt.contact_form.heading}</div>
              <div className='bodytext' style={{color: 'white', margin: '20px 70px'}}>
                {txt.contact_form.description}
              </div>
            </div>
            <div className='flex-section'>
              <div className='form-boxes'>
              <form onSubmit={submitForm}>
                <input type="text" className='form-box' id="firstname" name="firstname" placeholder={txt.contact_form.fields[0]} required/><br/>
                <input type="text" className='form-box' id="lastname" name="lastname" placeholder={txt.contact_form.fields[1]} required/><br/>
                <input type="text" className='form-box' id="phonenumber" name="phonenumber" placeholder={txt.contact_form.fields[2]} required/><br/>
                <input type="text" className='form-box' id="email" name="email" placeholder={txt.contact_form.fields[3]} required/><br/>
                
                <label className="form-label" for="contactmethod">{txt.contact_form.fields[4]}</label>
                <select id="contactmethod" name="contactmethod">
                  <option value="E-mail">{txt.contact_form.fields[3]}</option>
                  <option value="Text">{txt.contact_form.fields[5]}</option>
                  <option value="Call">{txt.contact_form.fields[6]}</option>
                </select><br/>
  
                {/*<input type="text" className='form-box' id="description" name="description" placeholder="Briefly describe your case" required style={{height: '120px'}}/><br/>*/}
                <textarea className='form-box' id="description" name="description" placeholder={txt.contact_form.fields[7]} required style={{height: '120px'}}/><br/>
  
                <input type="submit" id='formsubmit' value={txt.contact_form.fields[8]}/>
              </form> 
              </div>
            </div>
          </div>
        </div>
        <script type="text/javascript">
        (function() {
            emailjs.init('hw3BE-qSxgq-mbqKP')
        })()
        </script>
      </div>
    )
}

export default ContactForm;