import React, {Component} from 'react';
import {
  useParams
} from "react-router-dom";

import AttorneyCarousel from './AttorneyCarousel';

import img_pillars from './images/pillars_blue.jpeg'; 
import img_building from './images/building_blue.jpeg';

import Txt from './Txt';


function AttorneyProfile() {
    let txt = Txt.getTxt();
    const bios = new Map();
    const attorney_paths = [
        'james-mchargue',
        'matthew-jones',
        'brenton-schmitz',
        'jeff-nafziger',
        'daniel-klosowski',
        'adam-rosner',
    ];
    attorney_paths.forEach((path, i) => bios.set(path, txt.attorneys[i]));

    let params = useParams();
    let attorney = bios.get(params.name);

    return (
        <div id='attorney-background' style={{backgroundImage: `url(${img_pillars})`}}>
            <div className='flex-container' style={{padding: '10px 10px 30px', marginBottom: '60px', backgroundColor: 'white', boxShadow: "black 0px 0px 15px 0px"}}>
                <div className='flex-section'>
                    <img className='attorney-portrait' src={require(`${attorney.image}`)}></img>

                    <div style={{margin: '30px', color: 'red'}}>
                    insert accreditations here?
                    maybe a quote or accomplishment?
                    </div>
                </div>
                <div className='flex-section' style={{flexGrow: 1.25}}>
                    <div className='attorney-title'>{attorney.name}</div>
                    <div className='attorney-subtitle'>{attorney.subtitle}</div>
                    <div className='attorney-text'>{attorney.lawbio}</div>
                    <br/>
                    <div className='attorney-header'>Specialties</div>
                    <div className='attorney-specialties'><b>{attorney.specialties}</b></div>
                    <br/>
                    <div className='attorney-text'>{attorney.bio}</div>
                </div>
            </div>
            <AttorneyCarousel transparent/>
        </div>
    );
}

export default AttorneyProfile;