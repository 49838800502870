import React, {useState, Component} from "react";

import './App.css';


function Image(props) {
    let x = props.x == null ? 0 : props.x;
    let y = props.y == null ? 0 : props.y;

    if (props.height == null) {
        return (
            <div className='image-container' style={{'top': y, 'left': x, 'width': '100%', 'background-image': `linear-gradient(to top, #15294b80, #15294b80), url("${props.src}")`}}></div>
        );
    }

    return (
        <div className='image-container' style={{'top': y, 'left': x, 'height': props.height, 'width': '100%', 'background-image': `linear-gradient(to top, #15294b80, #15294b80), url("${props.src}")`}}></div>
    );
}

export default Image;