import React, {useState, Component} from "react";

import './App.css';


function SubtitleText(props) {
    let x = props.x == null ? 0 : props.x;
    let y = props.y == null ? 0 : props.y;
    let color = props.color == null ? 'black' : props.color;
    if (props.center != null) {
        return (
            <div className='subtitletext' style={{'top': y, 'width': '100%', 'textAlign': 'center', 'color': color}}>{props.children}</div>
        );
    }

    return (
        <div className='subtitletext' style={{'top': y, 'left': x, 'color': color}}>{props.children}</div>
    );
}

export default SubtitleText;