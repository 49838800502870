import React, {useState, Component} from "react";
import {
    Router,
    Routes,
    Route,
    Link,
    Outlet,
    useParams
} from "react-router-dom";
import './App.css';

import Txt from './Txt';


function AttorneyCarousel(props) {
    let txt = Txt.getTxt();

    let attorneyImages = [
        "Jeff_Naffziger",
        "Adam_Rosner",
        "James_McHargue",
        "Matt_Jones",
        "Brent_Schmitz",
        "Dan_Klosowski",
    ];
    let carouselStyle = {};
    if (props.transparent) {
        carouselStyle = {backgroundColor: 'transparent', border: '0px'}
    }
    return (
        <>
        <div style={{overflowX: 'hidden', display: 'flex', justifyContent: 'center', margin: '0 -60px'}}>
            <div className='attorney-carousel' style={carouselStyle}>
                {attorneyImages.map((image) => 
                    <div className='attorney-carousel-box' style={{backgroundImage: `url(${require("./images/attorney_" + image + ".jpeg")})`}}></div>
                )}
            </div>
        </div>
        <Link to='/attorneys' style={{textAlign: 'center'}}>
            <div className='attorney-carousel-button shortcut'>
                {txt.attorney_button} →
            </div>
        </Link>
        </>
    );
}

export default AttorneyCarousel;