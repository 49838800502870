import React, {Component} from 'react';
import {
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import TitleBanner from './TitleBanner';

import Txt from './Txt';


function PracticeArea(props) {
    let txt = Txt.getTxt();

    const content = new Map();
    content.set('practice-areas', {
        title: txt.navigation_bar[5],
        image: "./building.jpeg",
        body: GenericPracticeArea
    });
    content.set('workers-comp', {
        title: txt.navigation_bar[6],
        image: './images/factory_bg_dark.jpeg',
        body: WCPracticeArea
    });
    content.set('personal-injury', {
        title: txt.navigation_bar[7],
        image: './images/banner_pi.jpeg',
        body: PIPracticeArea
    });
    content.set('animals', {
        title: txt.navigation_bar[8],
        image: './images/banner_animal_attacks.jpeg',
        body: AnimalPracticeArea
    });
    content.set('car-accidents', {
        title: txt.navigation_bar[9],
        image: './images/banner_car_crash.jpeg',
        body: CarPracticeArea
    });
    content.set('premises', {
        title: txt.navigation_bar[10],
        image: './images/banner_premises.jpeg',
        body: PremisesPracticeArea
    });
    content.set('truck-accidents', {
        title: txt.navigation_bar[11],
        image: './images/banner_truck_crash.jpeg',
        body: TruckPracticeArea
    });
    content.set('who-we-represent', {
        title: txt.navigation_bar[12],
        image: './images/banner_handshake.jpeg',
        body: WorkersPracticeArea
    });

    let location = useLocation();
    let terminal = location.pathname.split('/').slice(-1)[0];
    let contentObject = content.get(terminal);
    let jsx = contentObject.body();

    return (
        <>
        <TitleBanner text={contentObject.title} src={contentObject.image}/>
        <div className='body-container'>
            <div className='flex-container' style={{padding: '30px'}}>
                <div className='flex-section'>
                    <div className='practice-area-text'>{jsx}</div>
                </div>
                <div className='flex-section' style={{flexBasis: '400px', flexGrow: 0}}>
                    <div className='pa-navbar'>
                        <Link to='/practice-areas'><b style={{fontSize: '40px'}}>{txt.navigation_bar[5]}</b></Link>
                        <div style={{width: '100%', height: '2px', backgroundColor: '#f1f8ff'}}></div>
                        <PANavButton href='/workers-comp' image='./images/factory_bg_dark.jpeg' text={txt.navigation_bar[6]}/>
                        <PANavButton href='/personal-injury' image='./images/banner_pi.jpeg' text={txt.navigation_bar[7]}/>
                        <PANavButton href='/personal-injury/car-accidents' image='./images/banner_car_crash.jpeg' text={txt.navigation_bar[8]}/>
                        <PANavButton href='/personal-injury/truck-accidents' image='./images/banner_truck_crash.jpeg' text={txt.navigation_bar[9]}/>
                        <PANavButton href='/personal-injury/premises' image='./images/banner_premises.jpeg' text={txt.navigation_bar[10]}/>
                        <PANavButton href='/personal-injury/animals' image='./images/banner_animal_attacks.jpeg' text={txt.navigation_bar[11]}/>
                        <PANavButton href='/who-we-represent' image='./images/banner_handshake.jpeg' text={txt.navigation_bar[12]}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

function PANavButton(props) {
    return (
        <Link to={props.href} style={{backgroundImage: `url("${require(`${props.image}`)}")`}}>{props.text}</Link>
    );
}

function GenericPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.practice_areas
    );
}

function WCPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.workers_comp
    );
}

function PIPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.personal_injury
    );
}

function AnimalPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.animal_attacks
    );
}

function CarPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.car_accidents
    );
}

function PremisesPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.premises_liability
    );
}

function TruckPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.truck_accidents
    );
}

function WorkersPracticeArea() {
    let txt = Txt.getTxt();
    return (
        txt.who_we_represent
    );
}

export default PracticeArea;