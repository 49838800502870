import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Dropdown from './Dropdown';
import logo from './logo.png';

import Txt from './Txt';


function switchLanguage() {
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain == 'es') {
        window.location.hostname = window.location.hostname.slice(3);
    } else {
        window.location.hostname = 'es.' + window.location.hostname;
    }
    
}

function Navbar() {
    let txt = Txt.getTxt();
    return (
        <>
        <div className='navbar'>
            <Link to={'/'}><div id="logo"><img src={logo} alt="McHargue & Jones, LLC"></img></div></Link>
            <Dropdown 
                base={{text: txt.navigation_bar[0], link: "about"}} 
                list={[
                    {text: txt.navigation_bar[1], link: "about"},
                    {text: txt.navigation_bar[2], link: "attorneys"},
                    {text: txt.navigation_bar[3], link: "faq"},
                ] 
            }/>
            <Dropdown 
                base={{text: txt.navigation_bar[5], link: "practice-areas"}} 
                list={[
                    {text: txt.navigation_bar[6], link: "workers-comp"},
                    {text: txt.navigation_bar[7], link: "personal-injury"},
                    {text: txt.navigation_bar[8], link: "personal-injury/animals"},
                    {text: txt.navigation_bar[9], link: "personal-injury/car-accidents"},
                    {text: txt.navigation_bar[10], link: "personal-injury/premises"},
                    {text: txt.navigation_bar[11], link: "personal-injury/truck-accidents"},
                    {text: txt.navigation_bar[12], link: "who-we-represent"},
                ] 
            }/>
            <Link to={'results'}><div className='navbutton'>{txt.navigation_bar[13]}</div></Link>
            <Link to={'reviews'}><div className='navbutton'>{txt.navigation_bar[14]}</div></Link>
            <Link to={'contact-us'}><div className='navbutton'>{txt.navigation_bar[15]}</div></Link>
            
            <div id='nav-misc'>
                <div>
                <h1>312-739-0000</h1>
                <h2 onClick={switchLanguage}>{txt.navigation_bar[16]}</h2>
                </div>
            </div>
        </div>
        <div className='navbarspacer'/>
        </>
    )
}


export default Navbar;