import React, {useState, Component} from "react";

import './App.css';


function TitleText(props) {
    let x = props.x == null ? 0 : props.x;
    let y = props.y == null ? 0 : props.y;
    let color = props.color == null ? 'black' : props.color;
    let fontsize = props.fontsize == null ? '90' : props.fontsize;
    if (props.center != null) {
        return (
            <div className='titletext' style={{'position': 'absolute', 'top': y, 'width': '100%', 'textAlign': 'center', 'color': color, 'font-size': fontsize}}>{props.children}</div>
        );
    }

    return (
        <div className='titletext' style={{'position': 'absolute', 'top': y, 'left': x, 'color': color, 'font-size': fontsize}}>{props.children}</div>
    );
}

export default TitleText;