import React, {useState, Component} from "react";

import './App.css';
import logo2 from './logo2.png';

import Txt from './Txt';


function Footer(props) {
    let txt = Txt.getTxt();
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='footer-section'>
                    <img src={logo2}></img>
                    <br/>
                    312-739-0000
                    <br style={{margin: '30px'}}/>
                    <div className='addresses'>
                        <SocialMediaLink href="https://www.facebook.com/mcharguelaw/" text="Facebook" icon={require('./images/link_facebook.png')}/><br/>
                        <SocialMediaLink href="https://www.linkedin.com/company/mchargue-jones-llc" text="LinkedIn" icon={require('./images/link_linkedin.png')}/><br/>
                        <SocialMediaLink href="https://www.youtube.com/channel/UCsv08hHg1OczkbeFe2X5O-w/featured" text="YouTube" icon={require('./images/link_youtube.png')}/><br/>
                    </div>

                    <div style={{paddingTop: '24px'}}>
                        <b>{txt.footer.offices_heading}</b><br/>
                        <div className='addresses'>
                            {txt.footer.address}
                            <br/>
                            <a href={"https://goo.gl/maps/icwt1GgruW1ezAAcA"} target="_blank" style={{textDecoration: 'underline'}}>{txt.footer.directions}</a>
                        </div>
                    </div>
                </div>
                <div className='footer-section'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.512609227919!2d-87.63381422401037!3d41.88183166518067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cbb822df1f1%3A0x51c373092c2ea9f7!2sMcHargue%20%26%20Jones%2C%20LLC!5e0!3m2!1sen!2sus!4v1691013388510!5m2!1sen!2sus" width="900" height="500" style={{border: 0, marginRight: "50px", boxShadow: "0px 0px 21px -5px black"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
}

function SocialMediaLink(props) {
    return (
        <div className='socialmedialink'>
            <img src={props.icon}></img>
            <a href={props.href} target="_blank" style={{textDecoration: 'underline'}}>{props.text}</a>
        </div>
    )
}

export default Footer;