import React, {useState, Component} from "react";
import GradientBanner from "./GradientBanner";
import TitleText from "./TitleText";
import Spacer from "./Spacer";

import './App.css';


function TitleBanner(props) {
    return (
      <>
        <TitleText y={220} x={80} color={'white'}>
          {props.text}
          <div className='title-underline'></div>
        </TitleText>
        <GradientBanner src={require(`${props.src}`)}/>
        <Spacer height={'250px'}/>
      </>
    );
}

export default TitleBanner;