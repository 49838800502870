import React, {useState, Component} from "react";
import {Link} from 'react-router-dom';

import './App.css';

function Dropdown(props) {
    /*
        base: {link: "attorney", text: "Our Team"}
        list: [
            {link: "james-p-mchargue", text: "James P. McHargue"}, 
            {link: "matthew-c-jones", text: "Matthew C. Jones"}
        ]
    */

    const [height, setHeight] = useState(0);
    const [opacity, setOpacity] = useState(0);
    
    function handleMouseEnter() {
        setHeight("auto");
        setOpacity(1.0);
    }
    function handleMouseLeave() {
        setHeight("0px");
        setOpacity(0.0);
    }

    return (
        <div className='dropdown' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Link to={props.base.link}>
                <div className='navbutton'>{props.base.text}</div>
            </Link>
            <div className='dropdownlist' style={{'height': height, 'opacity': opacity}}>
                {props.list.map((item) => (
                    <Link to={item.link} style={{"textDecoration": "none"}}><div className="dropdownbutton">{item.text}</div></Link>
                ))}
                <div style={{'height': '2px', 'backgroundColor': '#15294b'}}></div>
            </div>
        </div>
    );
}

export default Dropdown; 