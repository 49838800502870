import React, {useState, Component} from "react";

import './App.css';


function GradientBanner(props) {
    return (
        <div className='gradientbanner' style={{'background-image': `linear-gradient(to left, #15294b00, #15294bff), url("${props.src}")`}}></div>
    );
}

export default GradientBanner;