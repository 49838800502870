/**
 * A collection of every piece of text in the entire website.
 * This file exists to facilitate entering new text into the website
 * and translating the website to different languages.
*/
import React, {Component} from 'react';

class Txt {
    static text = {
    en: {
        navigation_bar: [
            "About",
            "About Us",
            "Our Team",
            "FAQ",
            "Blog",
            "Practice Areas",
            "Worker's Compensation",
            "Personal Injury",
            "Animal Attacks",
            "Car Accidents",
            "Premises Liability",
            "Truck Accidents",
            "Workers We Represent",
            "Case Results",
            "Testimonials",
            "Contact Us",
            "En español",
        ],
        homepage: {
            slogan: "Had a fall? Give us a call.",
            buttons: [
                "Live Chat",
                "Get a Free Case Evaluation",
                "Call Us",
            ],
            statistics: [
                "100", "years of experience",
                "$50,000,000", "won for our clients",
                "99%", "cases won",
            ],
            heading: "Chicago's Leading Personal Injury and Worker's Compensation Lawyers",
            elevator_pitch: "If you or someone you love sustained an injury at work or as a result of someone else’s negligence, turn to the trusted Chicago injury attorneys at McHargue & Jones, LLC today. Since 2000, we have helped individuals throughout Illinois recover compensation for their losses; winning millions of dollars on their behalves.",
            links: [
                <p><b>$10,000,000</b> won for clients in 2022</p>,
                <p>Attorneys from Chicago, <b>serving Chicago</b></p>,
                <p><b>"I was advised like I was a family member."</b></p>,
                <p>No fees <b>unless we win</b></p>
            ],
            heading2: "Accidents Illinois Makes It Right",
            case_results: [
                {amount: "$638,000", category: "Neck Injury", description: "The insurance company denied approval of his cervical fusion surgery, so we took the depositions of the doctors involved and tried the case, ultimately winning the surgery and proving his ongoing disability."},
                {amount: "$435,000", category: "Construction Accident", description: "This case went to trial when the insurance company denied his spine and shoulder surgeries and won approval and payment of his lost time and medical care."},
                {amount: "$465,000", category: "Machinery Accident", description: "We recovered $465,000 in future lost wages for a machine operator whose hands were trapped in a laminating machine, causing severe permanent damage to his hands even after multiple surgeries."},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
            ],
            mission_heading: "Our Mission",
            mission: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            long_description1: [
                "Originally named the Law Offices of James P. McHargue, McHargue & Jones, LLC was founded in 2000 with a single purpose in mind. The goal was to apply the law to improve the lives of people who have been injured at work or due to the negligence of others. The members of McHargue & Jones, LLC remain committed to this goal today. We have had the honor of helping thousands of people recover money and benefits related to work injuries, car accidents, dog bites, and many other accidents throughout the state of Illinois.",
                "The attorneys of McHargue & Jones, LLC have represented people who have been severely injured and family members of people who have died from their injuries. These tragic accidents have long-lasting and far-reaching effects on the injured person and their families. We recognize the significant and sensitive issues that our clients must deal with, and we strive to assist them in their recovery.",
                "Although many of the clients of McHargue & Jones, LLC have suffered severe, life-changing injuries, we know that thousands of people are involved in accidents every year which are not as severe. Many law firms are not willing to devote their time or resources to helping these injured people. The attorneys of McHargue & Jones, LLC believe that everyone should have access to legal help for their injuries.",
            ],
            long_description2: [
                "Not all injury cases are equal. Although many cases have clear facts and evidence that support a good settlement or trial award, there are many cases that have difficult or unclear facts that make an injury case more difficult to win. The attorneys of McHargue & Jones, LLC are proud to have the skill and willingness to fight the difficult cases for the benefit of our clients. We have been hired many times by clients who previously dealt with attorneys who were unwilling to aggressively litigate their cases. Under such circumstances, we have often achieved successful results for our clients despite the fact that their cases were initially denied when they hired us.",
                "Keep in mind that insurance companies have access to millions of dollars to defend the companies and people they insure. They are able to hire experienced defense attorneys to minimize the amount of money that they must pay out to injured people for compensation in accident cases. You can level the playing field by hiring experienced personal injury attorneys in Chicago to represent your interests!",
            ],
            testimonials: [
                {text: "Working with the great people at McHargue & Jones was one best decisions I've ever made.", author: "Mario Lopez"},
                {text: "I cannot say enough good things about Matt Jones, and his law firm. Matt is a consummate professional.", author: "Uriah Hose"},
                {text: "Would highly recommend Mr Jones and the entire firm to anyone entering a ... workers comp case.", author: "Hans Hanecke"},
                {text: "everyone in their office treated me like I was a Million Dollar case.", author: "Tobias Brady"},
                {text: "[McHargue and Jones] renewed my trust in attorneys.", author: "Cerritha Price"},
                {text: "McHargue & Jones are an advocate for you.", author: "Charles Vargas"},
            
                {text: "There were no surprises, kept me constantly informed and up to date.", author: "Sable Smith"},
                {text: "I would truly recommend any and everyone I know who needs help to call McHargue & Jones.", author: "Kvng JC"},
                {text: "Professional, honest, confident and down to earth, I would highly recommend this law firm to anyone suffering from a work injury.", author: "Judith Ashman"},
                {text: "The only regret I have is that I waiting to contact them.", author: "Holly Walczak"},
                {text: "it's nice to have someone in your corner taking you step by step. I am very happy to say I had Matt Jones in my corner.", author: "Mary Lynne Graebner"},
            ],
            see_more: "See More",
        },
        about_us: {
            heading: "Accidents Illinois has been making things right for the people of Chicago for the last 24 years.",
            short_description1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Erat velit scelerisque in dictum non consectetur a. Sed risus pretium quam vulputate dignissim suspendisse in est ante. Scelerisque purus semper eget duis at tellus. Accumsan lacus vel facilisis volutpat est velit egestas. Dolor magna eget est lorem ipsum dolor. Quisque non tellus orci ac. Aliquam faucibus purus in massa. Nunc consequat interdum varius sit amet mattis vulputate enim. Suscipit tellus mauris a diam maecenas sed enim ut. Vitae aliquet nec ullamcorper sit amet. A lacus vestibulum sed arcu non odio euismod. Erat imperdiet sed euismod nisi porta lorem mollis aliquam ut. Varius morbi enim nunc faucibus a pellentesque. Volutpat maecenas volutpat blandit aliquam etiam erat. Lorem donec massa sapien faucibus et. Nunc non blandit massa enim nec dui.",
            short_description2: "Integer malesuada nunc vel risus commodo viverra. Diam in arcu cursus euismod quis. Penatibus et magnis dis parturient montes nascetur ridiculus mus. Ultrices vitae auctor eu augue ut lectus arcu bibendum. Pellentesque massa placerat duis ultricies lacus sed turpis. Pellentesque diam volutpat commodo sed egestas egestas. Morbi tempus iaculis urna id volutpat lacus. Sed elementum tempus egestas sed. Morbi tincidunt ornare massa eget egestas. At tellus at urna condimentum mattis pellentesque id nibh tortor. At volutpat diam ut venenatis tellus in metus vulputate. Diam maecenas sed enim ut. Risus nullam eget felis eget nunc lobortis mattis. Eget mauris pharetra et ultrices neque ornare aenean euismod. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Quisque sagittis purus sit amet volutpat consequat mauris nunc.",
            long_description1: [
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisi cras fermentum odio eu feugiat pretium nibh ipsum consequat. Vitae nunc sed velit dignissim sodales ut eu sem integer. Dictumst vestibulum rhoncus est pellentesque elit. Suspendisse potenti nullam ac tortor vitae purus faucibus. Vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Interdum velit euismod in pellentesque massa placerat duis. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Sit amet cursus sit amet dictum sit amet justo. Quis blandit turpis cursus in hac habitasse.",
                "Laoreet sit amet cursus sit amet dictum. Ipsum dolor sit amet consectetur adipiscing elit duis. Faucibus scelerisque eleifend donec pretium vulputate. Nulla facilisi cras fermentum odio eu feugiat. Orci a scelerisque purus semper eget duis at tellus at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Tellus rutrum tellus pellentesque eu tincidunt. Habitant morbi tristique senectus et netus et malesuada. Facilisi nullam vehicula ipsum a arcu. Sagittis vitae et leo duis ut diam quam. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Suspendisse in est ante in. Posuere lorem ipsum dolor sit. Risus viverra adipiscing at in tellus integer. Justo eget magna fermentum iaculis eu non diam phasellus.",
            ],
            long_description2: [
                "Egestas dui id ornare arcu odio ut. Tellus cras adipiscing enim eu. Pretium fusce id velit ut tortor pretium viverra. Luctus venenatis lectus magna fringilla urna porttitor. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Scelerisque viverra mauris in aliquam. Malesuada fames ac turpis egestas sed. Varius vel pharetra vel turpis nunc eget. Consequat id porta nibh venenatis cras sed. Pellentesque id nibh tortor id aliquet. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum lectus. Vel orci porta non pulvinar neque laoreet suspendisse. Id eu nisl nunc mi ipsum faucibus vitae aliquet. Sed pulvinar proin gravida hendrerit lectus a. Id diam vel quam elementum pulvinar etiam non.",
                "Magna ac placerat vestibulum lectus mauris. Urna et pharetra pharetra massa massa ultricies mi quis. Vel quam elementum pulvinar etiam non quam. Ac auctor augue mauris augue neque. Lectus sit amet est placerat in egestas erat imperdiet sed. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Ut enim blandit volutpat maecenas volutpat blandit. Sodales neque sodales ut etiam sit. Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Libero id faucibus nisl tincidunt eget nullam non nisi est. Facilisis mauris sit amet massa vitae tortor.",
            ],
        },
        faq: {
            heading: "Frequently Asked Questions",
            questions: [
                {q: "This is a generic question. What is the answer?", a: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."},
                {q: "Could you also give me an answer to this question?", a: [
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In metus vulputate eu scelerisque. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Condimentum lacinia quis vel eros donec ac odio tempor orci. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Tempus egestas sed sed risus pretium. Risus pretium quam vulputate dignissim suspendisse in. Dolor sit amet consectetur adipiscing. Bibendum enim facilisis gravida neque convallis a. Morbi tempus iaculis urna id volutpat lacus laoreet non curabitur. Cursus turpis massa tincidunt dui ut ornare. Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Facilisis leo vel fringilla est. Tristique senectus et netus et malesuada fames ac turpis egestas. Habitant morbi tristique senectus et netus et malesuada. The end of this paragraph includes a link to ",
                    <a href="/practice-areas">another page on the site.</a>,
                    <br/>,
                    "Proin sed libero enim sed faucibus turpis in eu mi. Urna duis convallis convallis tellus id. In est ante in nibh mauris. Congue mauris rhoncus aenean vel. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Mauris pellentesque pulvinar pellentesque habitant. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Dolor sit amet consectetur adipiscing elit. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo. Dignissim convallis aenean et tortor. Id cursus metus aliquam eleifend mi in. Viverra accumsan in nisl nisi scelerisque eu. Eu sem integer vitae justo eget. Pellentesque massa placerat duis ultricies lacus sed. Etiam tempor orci eu lobortis elementum nibh.",
                ]},

            ]
        },
        attorney_overview: "Since 2000, McHargue & Jones, LLC has been helping individuals who sustained injuries at work or due to the negligence of others. As a result, our attorneys have a comprehensive understanding of these complex areas of law, as well as a lengthy track record of success.",
        attorney_button: "Read about our attorneys",
        attorney_specialties_heading: "Specialties",
        attorneys: [
            {
                name: 'James P. McHargue',
                subtitle: 'Founding Partner',
                image: './images/attorney_James_McHargue.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Matthew C. Jones',
                subtitle: 'Partner',
                image: './images/attorney_Matt_Jones.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Brenton M. Schmitz',
                subtitle: 'Partner',
                image: './images/attorney_Brent_Schmitz.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Jeff Naffziger',
                subtitle: 'Partner',
                image: './images/attorney_Jeff_Naffziger.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Daniel R. Klosowski',
                subtitle: 'Partner',
                image: './images/attorney_Dan_Klosowski.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Adam Rosner',
                subtitle: 'Attorney',
                image: './images/attorney_Adam_Rosner.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            }
        ],
        practice_areas: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        workers_comp: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        personal_injury: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        car_accidents: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        truck_accidents: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        premises_liability: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        animal_attacks: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        who_we_represent: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        case_results_statistic: "Over $50 million won across thousands of cases",
        case_results_summary: "Since 2000, McHargue & Jones, LLC has helped injured individuals across Illinois recover compensation for their losses. As a result, we have successfully helped thousands of clients resolve their problems, and we recovered millions of dollars in the process. We can assist you, too. To read about our past case results, please feel free to browse through them here, or contact us today at (312) 739-0000.",
        case_results: [
            {won: "$385,000", category: "Truck Accident", description: "This is a much longer description of the incident in more detail."},
            {won: "$405,000", category: "Slip and Fall", description: "Here's more information about that."},

        ],
        testimonials: {
            videos: [
                {title: "Video Title 1", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
                {title: "Second Video Title", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
                {title: "Title of Third Video", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            ],
            pages: [
                [
                  {text: "<b>I will continue to recommend them to anyone needing help with workman's comp injuries.</b> I am very pleased with the care and attention I was given by Attorney Jones and staff. They were always quick to respond, attentive to my needs, and very thorough with all the details my case required. I am very thankful they were there to help me through a difficult time and have and will continue to recommend them to anyone needing help with workman's comp injuries.", author: "Penny Martens"},
                  {text: "The staff and Matthew Jones were great. Great communication with handling my case. The staff and Matthew Jones were great and would definitely recommend their services.", author: "Peridot G."},
                  {text: "From the bottom of my heart, thank you so much. My experience with them was absolutely amazing. They are very good at what they do excellent service. Adam and Yasmin are the real deal. Thank you guys so much for working with me and helping me through this hard year. I was lost and afraid of the outcome but Adam reassured me that we were good. From the bottom of my heart, thank you so much. Your whole team is the bomb.", author: 'Barbara L.'},
                ],
                [
                  {text: "Overall we are very, very happy with this law firm, and if you ever are injured on the job you definitely need to call McHargue & Jones. Our experience with this law firm was excellent and we definitely would recommend them. Our attorney Brenton Schmitz was kind, caring and made sure we were well informed of the whole process along the way. He really handles the tough situations calmly and also eased our anxiety many times. The staff is also very friendly and understanding when you call, they always helped with messages to our attorney. Our attorney always returned our emails in a timely manner. Overall we are very, very happy with this law firm, and if you ever are injured on the job you definitely need to call McHargue & Jones.", author: "Donna Keller"},
                ]
            ],
            reviews_heading: "Reviews",
        },
        contact_form: {
            heading: "Sign Up for a Free Consultation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            fields: [
                "First Name",
                "Last Name",
                "Phone Number",
                "E-mail",
                "Preferred Contact Method:",
                "Text", 
                "Call",
                "Briefly describe your case",
                "Submit"
            ],
        },
        footer: {
            offices_heading: "Offices",
            address: <>
                105 W Madison St<br/>
                Suite 1600<br/>
                Chicago, IL 60602<br/>
                <br/></>,
            hours: "8:30AM - 5PM, Monday - Friday",
            directions: "Get Directions"
        }
    },

    es: {
        navigation_bar: [
            "Acerca",
            "Sobre Nosotros",
            "Nuestro Equipo",
            "Preguntas Más Frecuentes",
            "Blog",
            "Areas de Práctica",
            "Compensación Laboral",
            "Lesiones Personales",
            "Ataques de Animales",
            "Accidentes Automovilísticos",
            "Responsabilidad del Local",
            "Accidentes de Camiones",
            "A Quien Representamos",
            "Resultados",
            "Testimonios",
            "Contacta con Nosotros",
            "In English",
        ],
        homepage: {
            slogan: "Had a fall? Give us a call.",
            buttons: [
                "Live Chat",
                "Get a Free Case Evaluation",
                "Call Us",
            ],
            statistics: [
                "100", "years of experience",
                "$50,000,000", "won for our clients",
                "99%", "cases won",
            ],
            heading: "Chicago's Leading Personal Injury and Worker's Compensation Lawyers",
            elevator_pitch: "If you or someone you love sustained an injury at work or as a result of someone else’s negligence, turn to the trusted Chicago injury attorneys at McHargue & Jones, LLC today. Since 2000, we have helped individuals throughout Illinois recover compensation for their losses; winning millions of dollars on their behalves.",
            links: [
                <p><b>$10,000,000</b> won for clients in 2022</p>,
                <p>Attorneys from Chicago, <b>serving Chicago</b></p>,
                <p><b>"I was advised like I was a family member."</b></p>,
                <p>No fees <b>unless we win</b></p>
            ],
            heading2: "Accidents Illinois Makes It Right",
            case_results: [
                {amount: "$638,000", category: "Neck Injury", description: "The insurance company denied approval of his cervical fusion surgery, so we took the depositions of the doctors involved and tried the case, ultimately winning the surgery and proving his ongoing disability."},
                {amount: "$435,000", category: "Construction Accident", description: "This case went to trial when the insurance company denied his spine and shoulder surgeries and won approval and payment of his lost time and medical care."},
                {amount: "$465,000", category: "Machinery Accident", description: "We recovered $465,000 in future lost wages for a machine operator whose hands were trapped in a laminating machine, causing severe permanent damage to his hands even after multiple surgeries."},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
                {amount: "$300,000", category: "Category", description: "description"},
            ],
            mission_heading: "Our Mission",
            mission: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            long_description1: [
                "Originally named the Law Offices of James P. McHargue, McHargue & Jones, LLC was founded in 2000 with a single purpose in mind. The goal was to apply the law to improve the lives of people who have been injured at work or due to the negligence of others. The members of McHargue & Jones, LLC remain committed to this goal today. We have had the honor of helping thousands of people recover money and benefits related to work injuries, car accidents, dog bites, and many other accidents throughout the state of Illinois.",
                "The attorneys of McHargue & Jones, LLC have represented people who have been severely injured and family members of people who have died from their injuries. These tragic accidents have long-lasting and far-reaching effects on the injured person and their families. We recognize the significant and sensitive issues that our clients must deal with, and we strive to assist them in their recovery.",
                "Although many of the clients of McHargue & Jones, LLC have suffered severe, life-changing injuries, we know that thousands of people are involved in accidents every year which are not as severe. Many law firms are not willing to devote their time or resources to helping these injured people. The attorneys of McHargue & Jones, LLC believe that everyone should have access to legal help for their injuries.",
            ],
            long_description2: [
                "Not all injury cases are equal. Although many cases have clear facts and evidence that support a good settlement or trial award, there are many cases that have difficult or unclear facts that make an injury case more difficult to win. The attorneys of McHargue & Jones, LLC are proud to have the skill and willingness to fight the difficult cases for the benefit of our clients. We have been hired many times by clients who previously dealt with attorneys who were unwilling to aggressively litigate their cases. Under such circumstances, we have often achieved successful results for our clients despite the fact that their cases were initially denied when they hired us.",
                "Keep in mind that insurance companies have access to millions of dollars to defend the companies and people they insure. They are able to hire experienced defense attorneys to minimize the amount of money that they must pay out to injured people for compensation in accident cases. You can level the playing field by hiring experienced personal injury attorneys in Chicago to represent your interests!",
            ],
            testimonials: [
                {text: "Working with the great people at McHargue & Jones was one best decisions I've ever made.", author: "Mario Lopez"},
                {text: "I cannot say enough good things about Matt Jones, and his law firm. Matt is a consummate professional.", author: "Uriah Hose"},
                {text: "Would highly recommend Mr Jones and the entire firm to anyone entering a ... workers comp case.", author: "Hans Hanecke"},
                {text: "everyone in their office treated me like I was a Million Dollar case.", author: "Tobias Brady"},
                {text: "[McHargue and Jones] renewed my trust in attorneys.", author: "Cerritha Price"},
                {text: "McHargue & Jones are an advocate for you.", author: "Charles Vargas"},
            
                {text: "There were no surprises, kept me constantly informed and up to date.", author: "Sable Smith"},
                {text: "I would truly recommend any and everyone I know who needs help to call McHargue & Jones.", author: "Kvng JC"},
                {text: "Professional, honest, confident and down to earth, I would highly recommend this law firm to anyone suffering from a work injury.", author: "Judith Ashman"},
                {text: "The only regret I have is that I waiting to contact them.", author: "Holly Walczak"},
                {text: "it's nice to have someone in your corner taking you step by step. I am very happy to say I had Matt Jones in my corner.", author: "Mary Lynne Graebner"},
            ],
            see_more: "See More",
        },
        about_us: {
            heading: "Accidents Illinois has been making things right for the people of Chicago for the last 24 years.",
            short_description1: [
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Erat velit scelerisque in dictum non consectetur a. Sed risus pretium quam vulputate dignissim suspendisse in est ante. Scelerisque purus semper eget duis at tellus. Accumsan lacus vel facilisis volutpat est velit egestas. Dolor magna eget est lorem ipsum dolor. Quisque non tellus orci ac. Aliquam faucibus purus in massa. Nunc consequat interdum varius sit amet mattis vulputate enim. Suscipit tellus mauris a diam maecenas sed enim ut. Vitae aliquet nec ullamcorper sit amet. A lacus vestibulum sed arcu non odio euismod. Erat imperdiet sed euismod nisi porta lorem mollis aliquam ut. Varius morbi enim nunc faucibus a pellentesque. Volutpat maecenas volutpat blandit aliquam etiam erat. Lorem donec massa sapien faucibus et. Nunc non blandit massa enim nec dui.",
            ],
            short_description2: [
                "Integer malesuada nunc vel risus commodo viverra. Diam in arcu cursus euismod quis. Penatibus et magnis dis parturient montes nascetur ridiculus mus. Ultrices vitae auctor eu augue ut lectus arcu bibendum. Pellentesque massa placerat duis ultricies lacus sed turpis. Pellentesque diam volutpat commodo sed egestas egestas. Morbi tempus iaculis urna id volutpat lacus. Sed elementum tempus egestas sed. Morbi tincidunt ornare massa eget egestas. At tellus at urna condimentum mattis pellentesque id nibh tortor. At volutpat diam ut venenatis tellus in metus vulputate. Diam maecenas sed enim ut. Risus nullam eget felis eget nunc lobortis mattis. Eget mauris pharetra et ultrices neque ornare aenean euismod. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Quisque sagittis purus sit amet volutpat consequat mauris nunc.",
            ],
            long_description1: [
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Facilisi cras fermentum odio eu feugiat pretium nibh ipsum consequat. Vitae nunc sed velit dignissim sodales ut eu sem integer. Dictumst vestibulum rhoncus est pellentesque elit. Suspendisse potenti nullam ac tortor vitae purus faucibus. Vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Interdum velit euismod in pellentesque massa placerat duis. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Vitae aliquet nec ullamcorper sit amet risus nullam eget. Sit amet cursus sit amet dictum sit amet justo. Quis blandit turpis cursus in hac habitasse.",
                "Laoreet sit amet cursus sit amet dictum. Ipsum dolor sit amet consectetur adipiscing elit duis. Faucibus scelerisque eleifend donec pretium vulputate. Nulla facilisi cras fermentum odio eu feugiat. Orci a scelerisque purus semper eget duis at tellus at. Ipsum dolor sit amet consectetur adipiscing elit ut aliquam purus. Tellus rutrum tellus pellentesque eu tincidunt. Habitant morbi tristique senectus et netus et malesuada. Facilisi nullam vehicula ipsum a arcu. Sagittis vitae et leo duis ut diam quam. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Suspendisse in est ante in. Posuere lorem ipsum dolor sit. Risus viverra adipiscing at in tellus integer. Justo eget magna fermentum iaculis eu non diam phasellus.",
            ],
            long_description2: [
                "Egestas dui id ornare arcu odio ut. Tellus cras adipiscing enim eu. Pretium fusce id velit ut tortor pretium viverra. Luctus venenatis lectus magna fringilla urna porttitor. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. Scelerisque viverra mauris in aliquam. Malesuada fames ac turpis egestas sed. Varius vel pharetra vel turpis nunc eget. Consequat id porta nibh venenatis cras sed. Pellentesque id nibh tortor id aliquet. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum lectus. Vel orci porta non pulvinar neque laoreet suspendisse. Id eu nisl nunc mi ipsum faucibus vitae aliquet. Sed pulvinar proin gravida hendrerit lectus a. Id diam vel quam elementum pulvinar etiam non.",
                "Magna ac placerat vestibulum lectus mauris. Urna et pharetra pharetra massa massa ultricies mi quis. Vel quam elementum pulvinar etiam non quam. Ac auctor augue mauris augue neque. Lectus sit amet est placerat in egestas erat imperdiet sed. Volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Ut enim blandit volutpat maecenas volutpat blandit. Sodales neque sodales ut etiam sit. Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Libero id faucibus nisl tincidunt eget nullam non nisi est. Facilisis mauris sit amet massa vitae tortor.",
            ],
        },
        faq: {
            heading: "Frequently Asked Questions",
            questions: [
                {q: "This is a generic question. What is the answer?", a: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."},
                {q: "Could you also give me an answer to this question?", a: [
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In metus vulputate eu scelerisque. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Condimentum lacinia quis vel eros donec ac odio tempor orci. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim. Tempus egestas sed sed risus pretium. Risus pretium quam vulputate dignissim suspendisse in. Dolor sit amet consectetur adipiscing. Bibendum enim facilisis gravida neque convallis a. Morbi tempus iaculis urna id volutpat lacus laoreet non curabitur. Cursus turpis massa tincidunt dui ut ornare. Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Facilisis leo vel fringilla est. Tristique senectus et netus et malesuada fames ac turpis egestas. Habitant morbi tristique senectus et netus et malesuada. The end of this paragraph includes a link to ",
                    <a href="/practice-areas">another page on the site.</a>,
                    <br/>,
                    "Proin sed libero enim sed faucibus turpis in eu mi. Urna duis convallis convallis tellus id. In est ante in nibh mauris. Congue mauris rhoncus aenean vel. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Mauris pellentesque pulvinar pellentesque habitant. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Dolor sit amet consectetur adipiscing elit. Sed euismod nisi porta lorem mollis aliquam ut porttitor leo. Dignissim convallis aenean et tortor. Id cursus metus aliquam eleifend mi in. Viverra accumsan in nisl nisi scelerisque eu. Eu sem integer vitae justo eget. Pellentesque massa placerat duis ultricies lacus sed. Etiam tempor orci eu lobortis elementum nibh.",
                ]},

            ]
        },
        attorney_overview: "Since 2000, McHargue & Jones, LLC has been helping individuals who sustained injuries at work or due to the negligence of others. As a result, our attorneys have a comprehensive understanding of these complex areas of law, as well as a lengthy track record of success.",
        attorney_button: "Read about our attorneys",
        attorney_specialties_heading: "Specialties",
        attorneys: [
            {
                name: 'James P. McHargue',
                subtitle: 'Founding Partner',
                image: './images/attorney_James_McHargue.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Matthew C. Jones',
                subtitle: 'Partner',
                image: './images/attorney_Matt_Jones.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Brenton M. Schmitz',
                subtitle: 'Partner',
                image: './images/attorney_Brent_Schmitz.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Jeff Naffziger',
                subtitle: 'Partner',
                image: './images/attorney_Jeff_Naffziger.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Daniel R. Klosowski',
                subtitle: 'Partner',
                image: './images/attorney_Dan_Klosowski.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            },
            {
                name: 'Adam Rosner',
                subtitle: 'Attorney',
                image: './images/attorney_Adam_Rosner.jpeg',
                lawbio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
                specialties: "Personal Injury, Workers' Compensation",
                bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu mi bibendum neque egestas congue quisque. Arcu non sodales neque sodales. At augue eget arcu dictum. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Aliquet nibh praesent tristique magna sit amet purus gravida quis. Varius vel pharetra vel turpis nunc. Quisque id diam vel quam elementum pulvinar etiam non. Feugiat in ante metus dictum at tempor. Quam viverra orci sagittis eu. Aliquam etiam erat velit scelerisque in dictum non.',
            }
        ],
        practice_areas: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        workers_comp: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        personal_injury: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        car_accidents: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        truck_accidents: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        premises_liability: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        animal_attacks: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        who_we_represent: (
            <>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. A erat nam at lectus. Euismod elementum nisi quis eleifend quam adipiscing. Vestibulum lorem sed risus ultricies tristique nulla. Id neque aliquam vestibulum morbi blandit. Bibendum est ultricies integer quis. Nulla pharetra diam sit amet nisl. Placerat duis ultricies lacus sed turpis tincidunt id aliquet risus. Ultricies mi eget mauris pharetra et ultrices. Felis eget velit aliquet sagittis.
            </p>
            <h1>Our Practice Areas</h1>
            <p>
            <br/>
            Felis donec et odio pellentesque. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Enim eu turpis egestas pretium aenean. Amet nisl purus in mollis. Magna etiam tempor orci eu lobortis elementum. Adipiscing elit pellentesque habitant morbi. Enim nec dui nunc mattis enim ut tellus. Sapien eget mi proin sed libero enim. Quam vulputate dignissim suspendisse in est ante in nibh. Lobortis feugiat vivamus at augue eget arcu dictum. Ac felis donec et odio pellentesque diam volutpat commodo sed. Integer enim neque volutpat ac tincidunt vitae semper quis. Blandit aliquam etiam erat velit scelerisque in dictum non consectetur. Massa massa ultricies mi quis hendrerit dolor magna eget est. Ultrices vitae auctor eu augue ut lectus. Gravida dictum fusce ut placerat. Mauris ultrices eros in cursus turpis massa tincidunt dui. Eu feugiat pretium nibh ipsum consequat nisl vel pretium lectus.
            <ol>
                <li>Thing 1</li>
                <li>Thing 2</li>
                <li>Thing 3</li>
            </ol>
            
            </p>
            <h2>Subsection</h2>
            <p>
            Risus in hendrerit gravida rutrum quisque. Enim sit amet venenatis urna cursus. Ultrices neque ornare aenean euismod elementum nisi. Neque laoreet suspendisse interdum consectetur libero id faucibus. Fermentum iaculis eu non diam phasellus vestibulum. Posuere urna nec tincidunt praesent semper feugiat nibh sed pulvinar. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Maecenas ultricies mi eget mauris. Nisi est sit amet facilisis magna etiam tempor orci. Vitae auctor eu augue ut lectus arcu bibendum at. Posuere ac ut consequat semper viverra nam libero justo laoreet. Felis eget nunc lobortis mattis aliquam faucibus purus in massa. A iaculis at erat pellentesque. Purus sit amet volutpat consequat mauris nunc. Quam pellentesque nec nam aliquam. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Metus vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Consequat nisl vel pretium lectus quam id. Lorem donec massa sapien faucibus et molestie ac.
            <br/>
            <ul>
                <li><b>Item 1.</b> This is item 1.</li>
                <li><b>Item 2.</b> This is item 2.</li>
                <li><b>Item 3.</b> This is item 3.</li>
            </ul>
            Orci ac auctor augue mauris augue. Bibendum ut tristique et egestas. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat. Fringilla est ullamcorper eget nulla facilisi. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Aliquam ultrices sagittis orci a scelerisque purus. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Elit ut aliquam purus sit amet luctus venenatis. Sed turpis tincidunt id aliquet risus feugiat. Consectetur lorem donec massa sapien faucibus et. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Consequat nisl vel pretium lectus quam id leo in vitae. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Bibendum neque egestas congue quisque egestas diam. Vulputate sapien nec sagittis aliquam malesuada bibendum. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Semper risus in hendrerit gravida rutrum quisque non tellus. Integer enim neque volutpat ac tincidunt vitae semper. Non nisi est sit amet.
            <br/>
            </p>
            <h3>Subsubsection</h3>
            <p>
            Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Tristique sollicitudin nibh sit amet. Viverra adipiscing at in tellus integer. Lectus arcu bibendum at varius vel pharetra. Enim ut tellus elementum sagittis vitae et leo duis. Tristique nulla aliquet enim tortor. Tortor consequat id porta nibh venenatis. Tincidunt lobortis feugiat vivamus at. Augue interdum velit euismod in pellentesque. Vitae aliquet nec ullamcorper sit amet risus nullam eget.</p>
            </>
        ),
        case_results_statistic: "Over $50 million won across thousands of cases",
        case_results_summary: "Since 2000, McHargue & Jones, LLC has helped injured individuals across Illinois recover compensation for their losses. As a result, we have successfully helped thousands of clients resolve their problems, and we recovered millions of dollars in the process. We can assist you, too. To read about our past case results, please feel free to browse through them here, or contact us today at (312) 739-0000.",
        case_results: [
            {won: "$385,000", category: "Truck Accident", description: "This is a much longer description of the incident in more detail."},
            {won: "$405,000", category: "Slip and Fall", description: "Here's more information about that."},

        ],
        testimonials: {
            videos: [
                {title: "Video Title 1", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
                {title: "Second Video Title", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
                {title: "Title of Third Video", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            ],
            pages: [
                [
                  {text: "<b>I will continue to recommend them to anyone needing help with workman's comp injuries.</b> I am very pleased with the care and attention I was given by Attorney Jones and staff. They were always quick to respond, attentive to my needs, and very thorough with all the details my case required. I am very thankful they were there to help me through a difficult time and have and will continue to recommend them to anyone needing help with workman's comp injuries.", author: "Penny Martens"},
                  {text: "The staff and Matthew Jones were great. Great communication with handling my case. The staff and Matthew Jones were great and would definitely recommend their services.", author: "Peridot G."},
                  {text: "From the bottom of my heart, thank you so much. My experience with them was absolutely amazing. They are very good at what they do excellent service. Adam and Yasmin are the real deal. Thank you guys so much for working with me and helping me through this hard year. I was lost and afraid of the outcome but Adam reassured me that we were good. From the bottom of my heart, thank you so much. Your whole team is the bomb.", author: 'Barbara L.'},
                ],
                [
                  {text: "Overall we are very, very happy with this law firm, and if you ever are injured on the job you definitely need to call McHargue & Jones. Our experience with this law firm was excellent and we definitely would recommend them. Our attorney Brenton Schmitz was kind, caring and made sure we were well informed of the whole process along the way. He really handles the tough situations calmly and also eased our anxiety many times. The staff is also very friendly and understanding when you call, they always helped with messages to our attorney. Our attorney always returned our emails in a timely manner. Overall we are very, very happy with this law firm, and if you ever are injured on the job you definitely need to call McHargue & Jones.", author: "Donna Keller"},
                ]
            ],
            reviews_heading: "Reviews",
        },
        contact_form: {
            heading: "Sign Up for a Free Consultation",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            fields: [
                "First Name",
                "Last Name",
                "Phone Number",
                "E-mail",
                "Preferred Contact Method:",
                "Text", 
                "Call",
                "Briefly describe your case",
                "Submit"
            ],
        },
        footer: {
            offices_heading: "Offices",
            address: <>
                105 W Madison St<br/>
                Suite 1600<br/>
                Chicago, IL 60602<br/>
                <br/></>,
            hours: "8:30AM - 5PM, Monday - Friday",
            directions: "Get Directions"
        }
    }
    };

    static getTxt() {
        let subdomain = window.location.hostname.split('.')[0];
        let lang = subdomain == 'es' ? 'es' : 'en';
        return Txt.text[lang];
    }
}

export default Txt;