import React, {useState, Component} from "react";

import './App.css';


function Spacer(props) {
    return (
        <div style={{'height': props.height}}></div>
    );
}

export default Spacer;